import { default as awardsGZE03KY3bbMeta } from "/data/pages/about-us/awards.vue?macro=true";
import { default as in_45the_45media3XkjronnXvMeta } from "/data/pages/about-us/in-the-media.vue?macro=true";
import { default as indexw4ONCROkyTMeta } from "/data/pages/about-us/index.vue?macro=true";
import { default as _91slug_93cXioxiEr7bMeta } from "/data/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93BF43XaeoiDMeta } from "/data/pages/blog/authors/[slug].vue?macro=true";
import { default as indexjKH6jSfTSdMeta } from "/data/pages/blog/authors/index.vue?macro=true";
import { default as _91slug_93cEB1nN3s1oMeta } from "/data/pages/blog/categories/[slug].vue?macro=true";
import { default as index3JdQccMEcZMeta } from "/data/pages/blog/index.vue?macro=true";
import { default as _91slug_93W2c0teDQWlMeta } from "/data/pages/blog/tags/[slug].vue?macro=true";
import { default as indexVag119Ai9QMeta } from "/data/pages/checkout/index.vue?macro=true";
import { default as thank_45youjeL9gqfYoWMeta } from "/data/pages/checkout/thank-you.vue?macro=true";
import { default as _91slug_93eL5cIth4EPMeta } from "/data/pages/clinics/[slug].vue?macro=true";
import { default as index5eSz7sUw3TMeta } from "/data/pages/clinics/index.vue?macro=true";
import { default as contact_45usVb7LZb4YQxMeta } from "/data/pages/contact-us.vue?macro=true";
import { default as indexojFm3fXhJiMeta } from "/data/pages/direct-payment/[order_token]/index.vue?macro=true";
import { default as _91slug_93wLojpWP9YDMeta } from "/data/pages/faqs/[slug].vue?macro=true";
import { default as indexLfZweG7Ef2Meta } from "/data/pages/faqs/index.vue?macro=true";
import { default as index0sTCdVluXDMeta } from "/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue?macro=true";
import { default as _91condition_93IYA8PUM8TrMeta } from "/data/pages/help-and-advice/[category]/[slug]/[condition].vue?macro=true";
import { default as indexZeiT6zQX5VMeta } from "/data/pages/help-and-advice/[category]/[slug]/index.vue?macro=true";
import { default as index9wTWHSpoWsMeta } from "/data/pages/help-and-advice/[category]/index.vue?macro=true";
import { default as indexjAaK7ryr1uMeta } from "/data/pages/help-and-advice/index.vue?macro=true";
import { default as indexwJM0pWSZGwMeta } from "/data/pages/index.vue?macro=true";
import { default as _91slug_93VAORsljsIBMeta } from "/data/pages/legal-and-privacy/[slug].vue?macro=true";
import { default as indexSWPrnbyjTJMeta } from "/data/pages/legal-and-privacy/index.vue?macro=true";
import { default as policy_45detailtnulJGjISmMeta } from "/data/pages/policy-detail.vue?macro=true";
import { default as index2QwGis8PcNMeta } from "/data/pages/reviews/index.vue?macro=true";
import { default as indexjNcQzb30nMMeta } from "/data/pages/tests/[id]/[slug]/index.vue?macro=true";
import { default as index1s4p7SZOGtMeta } from "/data/pages/tests/[id]/index.vue?macro=true";
import { default as _91id_93ipsotPlbY8Meta } from "/data/pages/tests/health-and-wellness/[id].vue?macro=true";
import { default as indexi5j1BAjR1wMeta } from "/data/pages/tests/index.vue?macro=true";
export default [
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en",
    path: awardsGZE03KY3bbMeta?.path ?? "/en/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-be",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-be/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-bo",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-bo/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-bo",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-bo/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-bz",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-bz/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-bz",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-bz/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-ca",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-ca/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-cl",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-cl/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-cl",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-cl/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-co",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-co/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-co",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-co/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-cr",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-cr/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-cr",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-cr/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-ec",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-ec/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-ec",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-ec/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-es",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-es/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-es",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-es/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-ge",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-ge/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-gb",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-gb/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-hk",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-hk/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-hu",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-hu/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___hu-hu",
    path: awardsGZE03KY3bbMeta?.path ?? "/hu-hu/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-in",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-in/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-au",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-au/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-mx",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-mx/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-mx",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-mx/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-ni",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-ni/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-ni",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-ni/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-nl",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-nl/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-pa",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-pa/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-pa",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-pa/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-pe",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-pe/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-pe",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-pe/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-pl",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-pl/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___pl-pl",
    path: awardsGZE03KY3bbMeta?.path ?? "/pl-pl/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-pr",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-pr/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-pr",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-pr/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-pt",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-pt/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___pt-pt",
    path: awardsGZE03KY3bbMeta?.path ?? "/pt-pt/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-py",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-py/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-py",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-py/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-za",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-za/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-be",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-be/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-bo",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-bo/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-bo",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-bo/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-bz",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-bz/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-bz",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-bz/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-ca",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-ca/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-cl",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-cl/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-cl",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-cl/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-co",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-co/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-co",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-co/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-cr",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-cr/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-cr",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-cr/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-ec",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-ec/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-ec",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-ec/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-es",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-es/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-es",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-es/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-ge",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-ge/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-gb",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-gb/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-hk",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-hk/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-hu",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-hu/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___hu-hu",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/hu-hu/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-in",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-in/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-au",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-au/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-mx",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-mx/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-mx",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-mx/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-ni",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-ni/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-ni",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-ni/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-nl",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-nl/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-pa",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-pa/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-pa",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-pa/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-pe",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-pe/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-pe",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-pe/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-pl",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-pl/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___pl-pl",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/pl-pl/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-pr",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-pr/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-pr",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-pr/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-pt",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-pt/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___pt-pt",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/pt-pt/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-py",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-py/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-py",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-py/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-za",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-za/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en",
    path: indexw4ONCROkyTMeta?.path ?? "/en/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-be",
    path: indexw4ONCROkyTMeta?.path ?? "/en-be/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-bo",
    path: indexw4ONCROkyTMeta?.path ?? "/en-bo/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-bo",
    path: indexw4ONCROkyTMeta?.path ?? "/es-bo/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-bz",
    path: indexw4ONCROkyTMeta?.path ?? "/en-bz/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-bz",
    path: indexw4ONCROkyTMeta?.path ?? "/es-bz/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-ca",
    path: indexw4ONCROkyTMeta?.path ?? "/en-ca/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-cl",
    path: indexw4ONCROkyTMeta?.path ?? "/en-cl/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-cl",
    path: indexw4ONCROkyTMeta?.path ?? "/es-cl/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-co",
    path: indexw4ONCROkyTMeta?.path ?? "/en-co/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-co",
    path: indexw4ONCROkyTMeta?.path ?? "/es-co/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-cr",
    path: indexw4ONCROkyTMeta?.path ?? "/en-cr/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-cr",
    path: indexw4ONCROkyTMeta?.path ?? "/es-cr/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-ec",
    path: indexw4ONCROkyTMeta?.path ?? "/en-ec/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-ec",
    path: indexw4ONCROkyTMeta?.path ?? "/es-ec/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-es",
    path: indexw4ONCROkyTMeta?.path ?? "/en-es/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-es",
    path: indexw4ONCROkyTMeta?.path ?? "/es-es/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-ge",
    path: indexw4ONCROkyTMeta?.path ?? "/en-ge/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-gb",
    path: indexw4ONCROkyTMeta?.path ?? "/en-gb/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-hk",
    path: indexw4ONCROkyTMeta?.path ?? "/en-hk/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-hu",
    path: indexw4ONCROkyTMeta?.path ?? "/en-hu/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___hu-hu",
    path: indexw4ONCROkyTMeta?.path ?? "/hu-hu/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-in",
    path: indexw4ONCROkyTMeta?.path ?? "/en-in/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-au",
    path: indexw4ONCROkyTMeta?.path ?? "/en-au/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-mx",
    path: indexw4ONCROkyTMeta?.path ?? "/en-mx/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-mx",
    path: indexw4ONCROkyTMeta?.path ?? "/es-mx/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-ni",
    path: indexw4ONCROkyTMeta?.path ?? "/en-ni/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-ni",
    path: indexw4ONCROkyTMeta?.path ?? "/es-ni/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-nl",
    path: indexw4ONCROkyTMeta?.path ?? "/en-nl/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-pa",
    path: indexw4ONCROkyTMeta?.path ?? "/en-pa/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-pa",
    path: indexw4ONCROkyTMeta?.path ?? "/es-pa/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-pe",
    path: indexw4ONCROkyTMeta?.path ?? "/en-pe/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-pe",
    path: indexw4ONCROkyTMeta?.path ?? "/es-pe/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-pl",
    path: indexw4ONCROkyTMeta?.path ?? "/en-pl/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___pl-pl",
    path: indexw4ONCROkyTMeta?.path ?? "/pl-pl/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-pr",
    path: indexw4ONCROkyTMeta?.path ?? "/en-pr/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-pr",
    path: indexw4ONCROkyTMeta?.path ?? "/es-pr/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-pt",
    path: indexw4ONCROkyTMeta?.path ?? "/en-pt/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___pt-pt",
    path: indexw4ONCROkyTMeta?.path ?? "/pt-pt/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-py",
    path: indexw4ONCROkyTMeta?.path ?? "/en-py/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-py",
    path: indexw4ONCROkyTMeta?.path ?? "/es-py/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-za",
    path: indexw4ONCROkyTMeta?.path ?? "/en-za/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cXioxiEr7bMeta?.name ?? "blog-slug___en",
    path: _91slug_93cXioxiEr7bMeta?.path ?? "/en/blog/:slug()",
    meta: _91slug_93cXioxiEr7bMeta || {},
    alias: _91slug_93cXioxiEr7bMeta?.alias || [],
    redirect: _91slug_93cXioxiEr7bMeta?.redirect || undefined,
    component: () => import("/data/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BF43XaeoiDMeta?.name ?? "blog-authors-slug___en",
    path: _91slug_93BF43XaeoiDMeta?.path ?? "/en/blog/authors/:slug()",
    meta: _91slug_93BF43XaeoiDMeta || {},
    alias: _91slug_93BF43XaeoiDMeta?.alias || [],
    redirect: _91slug_93BF43XaeoiDMeta?.redirect || undefined,
    component: () => import("/data/pages/blog/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexjKH6jSfTSdMeta?.name ?? "blog-authors___en",
    path: indexjKH6jSfTSdMeta?.path ?? "/en/blog/authors",
    meta: indexjKH6jSfTSdMeta || {},
    alias: indexjKH6jSfTSdMeta?.alias || [],
    redirect: indexjKH6jSfTSdMeta?.redirect || undefined,
    component: () => import("/data/pages/blog/authors/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cEB1nN3s1oMeta?.name ?? "blog-categories-slug___en",
    path: _91slug_93cEB1nN3s1oMeta?.path ?? "/en/blog/categories/:slug()",
    meta: _91slug_93cEB1nN3s1oMeta || {},
    alias: _91slug_93cEB1nN3s1oMeta?.alias || [],
    redirect: _91slug_93cEB1nN3s1oMeta?.redirect || undefined,
    component: () => import("/data/pages/blog/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3JdQccMEcZMeta?.name ?? "blog___en",
    path: index3JdQccMEcZMeta?.path ?? "/en/blog",
    meta: index3JdQccMEcZMeta || {},
    alias: index3JdQccMEcZMeta?.alias || [],
    redirect: index3JdQccMEcZMeta?.redirect || undefined,
    component: () => import("/data/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93W2c0teDQWlMeta?.name ?? "blog-tags-slug___en",
    path: _91slug_93W2c0teDQWlMeta?.path ?? "/en/blog/tags/:slug()",
    meta: _91slug_93W2c0teDQWlMeta || {},
    alias: _91slug_93W2c0teDQWlMeta?.alias || [],
    redirect: _91slug_93W2c0teDQWlMeta?.redirect || undefined,
    component: () => import("/data/pages/blog/tags/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en",
    path: indexVag119Ai9QMeta?.path ?? "/en/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-be",
    path: indexVag119Ai9QMeta?.path ?? "/en-be/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-bo",
    path: indexVag119Ai9QMeta?.path ?? "/en-bo/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-bo",
    path: indexVag119Ai9QMeta?.path ?? "/es-bo/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-bz",
    path: indexVag119Ai9QMeta?.path ?? "/en-bz/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-bz",
    path: indexVag119Ai9QMeta?.path ?? "/es-bz/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-ca",
    path: indexVag119Ai9QMeta?.path ?? "/en-ca/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-cl",
    path: indexVag119Ai9QMeta?.path ?? "/en-cl/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-cl",
    path: indexVag119Ai9QMeta?.path ?? "/es-cl/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-co",
    path: indexVag119Ai9QMeta?.path ?? "/en-co/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-co",
    path: indexVag119Ai9QMeta?.path ?? "/es-co/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-cr",
    path: indexVag119Ai9QMeta?.path ?? "/en-cr/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-cr",
    path: indexVag119Ai9QMeta?.path ?? "/es-cr/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-ec",
    path: indexVag119Ai9QMeta?.path ?? "/en-ec/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-ec",
    path: indexVag119Ai9QMeta?.path ?? "/es-ec/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-es",
    path: indexVag119Ai9QMeta?.path ?? "/en-es/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-es",
    path: indexVag119Ai9QMeta?.path ?? "/es-es/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-ge",
    path: indexVag119Ai9QMeta?.path ?? "/en-ge/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-gb",
    path: indexVag119Ai9QMeta?.path ?? "/en-gb/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-hk",
    path: indexVag119Ai9QMeta?.path ?? "/en-hk/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-hu",
    path: indexVag119Ai9QMeta?.path ?? "/en-hu/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___hu-hu",
    path: indexVag119Ai9QMeta?.path ?? "/hu-hu/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-in",
    path: indexVag119Ai9QMeta?.path ?? "/en-in/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-au",
    path: indexVag119Ai9QMeta?.path ?? "/en-au/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-mx",
    path: indexVag119Ai9QMeta?.path ?? "/en-mx/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-mx",
    path: indexVag119Ai9QMeta?.path ?? "/es-mx/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-ni",
    path: indexVag119Ai9QMeta?.path ?? "/en-ni/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-ni",
    path: indexVag119Ai9QMeta?.path ?? "/es-ni/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-nl",
    path: indexVag119Ai9QMeta?.path ?? "/en-nl/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-pa",
    path: indexVag119Ai9QMeta?.path ?? "/en-pa/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-pa",
    path: indexVag119Ai9QMeta?.path ?? "/es-pa/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-pe",
    path: indexVag119Ai9QMeta?.path ?? "/en-pe/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-pe",
    path: indexVag119Ai9QMeta?.path ?? "/es-pe/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-pl",
    path: indexVag119Ai9QMeta?.path ?? "/en-pl/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___pl-pl",
    path: indexVag119Ai9QMeta?.path ?? "/pl-pl/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-pr",
    path: indexVag119Ai9QMeta?.path ?? "/en-pr/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-pr",
    path: indexVag119Ai9QMeta?.path ?? "/es-pr/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-pt",
    path: indexVag119Ai9QMeta?.path ?? "/en-pt/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___pt-pt",
    path: indexVag119Ai9QMeta?.path ?? "/pt-pt/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-py",
    path: indexVag119Ai9QMeta?.path ?? "/en-py/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-py",
    path: indexVag119Ai9QMeta?.path ?? "/es-py/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-za",
    path: indexVag119Ai9QMeta?.path ?? "/en-za/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-be",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-be/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-bo",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-bo/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-bo",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-bo/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-bz",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-bz/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-bz",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-bz/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-ca",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-ca/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-cl",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-cl/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-cl",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-cl/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-co",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-co/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-co",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-co/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-cr",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-cr/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-cr",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-cr/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-ec",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-ec/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-ec",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-ec/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-es",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-es/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-es",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-es/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-ge",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-ge/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-gb",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-gb/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-hk",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-hk/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-hu",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-hu/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___hu-hu",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/hu-hu/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-in",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-in/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-au",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-au/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-mx",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-mx/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-mx",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-mx/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-ni",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-ni/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-ni",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-ni/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-nl",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-nl/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-pa",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-pa/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-pa",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-pa/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-pe",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-pe/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-pe",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-pe/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-pl",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-pl/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___pl-pl",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/pl-pl/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-pr",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-pr/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-pr",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-pr/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-pt",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-pt/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___pt-pt",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/pt-pt/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-py",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-py/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-py",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-py/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-za",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-za/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-be",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-be/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-bo",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-bo/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-bo",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-bo/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-bz",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-bz/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-bz",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-bz/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-ca",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-ca/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-cl",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-cl/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-cl",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-cl/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-co",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-co/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-co",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-co/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-cr",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-cr/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-cr",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-cr/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-ec",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-ec/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-ec",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-ec/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-es",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-es/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-es",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-es/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-ge",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-ge/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-gb",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-gb/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-hk",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-hk/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-hu",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-hu/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___hu-hu",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/hu-hu/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-in",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-in/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-au",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-au/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-mx",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-mx/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-mx",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-mx/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-ni",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-ni/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-ni",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-ni/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-nl",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-nl/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-pa",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-pa/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-pa",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-pa/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-pe",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-pe/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-pe",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-pe/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-pl",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-pl/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___pl-pl",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/pl-pl/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-pr",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-pr/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-pr",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-pr/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-pt",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-pt/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___pt-pt",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/pt-pt/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-py",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-py/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-py",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-py/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-za",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-za/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en",
    path: index5eSz7sUw3TMeta?.path ?? "/en/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-be",
    path: index5eSz7sUw3TMeta?.path ?? "/en-be/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-bo",
    path: index5eSz7sUw3TMeta?.path ?? "/en-bo/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-bo",
    path: index5eSz7sUw3TMeta?.path ?? "/es-bo/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-bz",
    path: index5eSz7sUw3TMeta?.path ?? "/en-bz/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-bz",
    path: index5eSz7sUw3TMeta?.path ?? "/es-bz/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-ca",
    path: index5eSz7sUw3TMeta?.path ?? "/en-ca/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-cl",
    path: index5eSz7sUw3TMeta?.path ?? "/en-cl/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-cl",
    path: index5eSz7sUw3TMeta?.path ?? "/es-cl/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-co",
    path: index5eSz7sUw3TMeta?.path ?? "/en-co/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-co",
    path: index5eSz7sUw3TMeta?.path ?? "/es-co/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-cr",
    path: index5eSz7sUw3TMeta?.path ?? "/en-cr/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-cr",
    path: index5eSz7sUw3TMeta?.path ?? "/es-cr/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-ec",
    path: index5eSz7sUw3TMeta?.path ?? "/en-ec/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-ec",
    path: index5eSz7sUw3TMeta?.path ?? "/es-ec/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-es",
    path: index5eSz7sUw3TMeta?.path ?? "/en-es/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-es",
    path: index5eSz7sUw3TMeta?.path ?? "/es-es/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-ge",
    path: index5eSz7sUw3TMeta?.path ?? "/en-ge/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-gb",
    path: index5eSz7sUw3TMeta?.path ?? "/en-gb/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-hk",
    path: index5eSz7sUw3TMeta?.path ?? "/en-hk/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-hu",
    path: index5eSz7sUw3TMeta?.path ?? "/en-hu/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___hu-hu",
    path: index5eSz7sUw3TMeta?.path ?? "/hu-hu/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-in",
    path: index5eSz7sUw3TMeta?.path ?? "/en-in/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-au",
    path: index5eSz7sUw3TMeta?.path ?? "/en-au/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-mx",
    path: index5eSz7sUw3TMeta?.path ?? "/en-mx/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-mx",
    path: index5eSz7sUw3TMeta?.path ?? "/es-mx/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-ni",
    path: index5eSz7sUw3TMeta?.path ?? "/en-ni/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-ni",
    path: index5eSz7sUw3TMeta?.path ?? "/es-ni/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-nl",
    path: index5eSz7sUw3TMeta?.path ?? "/en-nl/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-pa",
    path: index5eSz7sUw3TMeta?.path ?? "/en-pa/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-pa",
    path: index5eSz7sUw3TMeta?.path ?? "/es-pa/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-pe",
    path: index5eSz7sUw3TMeta?.path ?? "/en-pe/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-pe",
    path: index5eSz7sUw3TMeta?.path ?? "/es-pe/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-pl",
    path: index5eSz7sUw3TMeta?.path ?? "/en-pl/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___pl-pl",
    path: index5eSz7sUw3TMeta?.path ?? "/pl-pl/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-pr",
    path: index5eSz7sUw3TMeta?.path ?? "/en-pr/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-pr",
    path: index5eSz7sUw3TMeta?.path ?? "/es-pr/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-pt",
    path: index5eSz7sUw3TMeta?.path ?? "/en-pt/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___pt-pt",
    path: index5eSz7sUw3TMeta?.path ?? "/pt-pt/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-py",
    path: index5eSz7sUw3TMeta?.path ?? "/en-py/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-py",
    path: index5eSz7sUw3TMeta?.path ?? "/es-py/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-za",
    path: index5eSz7sUw3TMeta?.path ?? "/en-za/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-be",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-be/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-bo",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-bo/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-bo",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-bo/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-bz",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-bz/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-bz",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-bz/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-ca",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-ca/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-cl",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-cl/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-cl",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-cl/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-co",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-co/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-co",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-co/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-cr",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-cr/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-cr",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-cr/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-ec",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-ec/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-ec",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-ec/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-es",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-es/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-es",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-es/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-ge",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-ge/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-gb",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-gb/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-hk",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-hk/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-hu",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-hu/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___hu-hu",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/hu-hu/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-in",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-in/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-au",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-au/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-mx",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-mx/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-mx",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-mx/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-ni",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-ni/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-ni",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-ni/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-nl",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-nl/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-pa",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-pa/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-pa",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-pa/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-pe",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-pe/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-pe",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-pe/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-pl",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-pl/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___pl-pl",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/pl-pl/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-pr",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-pr/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-pr",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-pr/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-pt",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-pt/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___pt-pt",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/pt-pt/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-py",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-py/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-py",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-py/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-za",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-za/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en",
    path: indexojFm3fXhJiMeta?.path ?? "/en/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-be",
    path: indexojFm3fXhJiMeta?.path ?? "/en-be/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-bo",
    path: indexojFm3fXhJiMeta?.path ?? "/en-bo/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-bo",
    path: indexojFm3fXhJiMeta?.path ?? "/es-bo/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-bz",
    path: indexojFm3fXhJiMeta?.path ?? "/en-bz/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-bz",
    path: indexojFm3fXhJiMeta?.path ?? "/es-bz/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-ca",
    path: indexojFm3fXhJiMeta?.path ?? "/en-ca/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-cl",
    path: indexojFm3fXhJiMeta?.path ?? "/en-cl/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-cl",
    path: indexojFm3fXhJiMeta?.path ?? "/es-cl/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-co",
    path: indexojFm3fXhJiMeta?.path ?? "/en-co/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-co",
    path: indexojFm3fXhJiMeta?.path ?? "/es-co/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-cr",
    path: indexojFm3fXhJiMeta?.path ?? "/en-cr/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-cr",
    path: indexojFm3fXhJiMeta?.path ?? "/es-cr/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-ec",
    path: indexojFm3fXhJiMeta?.path ?? "/en-ec/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-ec",
    path: indexojFm3fXhJiMeta?.path ?? "/es-ec/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-es",
    path: indexojFm3fXhJiMeta?.path ?? "/en-es/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-es",
    path: indexojFm3fXhJiMeta?.path ?? "/es-es/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-ge",
    path: indexojFm3fXhJiMeta?.path ?? "/en-ge/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-gb",
    path: indexojFm3fXhJiMeta?.path ?? "/en-gb/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-hk",
    path: indexojFm3fXhJiMeta?.path ?? "/en-hk/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-hu",
    path: indexojFm3fXhJiMeta?.path ?? "/en-hu/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___hu-hu",
    path: indexojFm3fXhJiMeta?.path ?? "/hu-hu/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-in",
    path: indexojFm3fXhJiMeta?.path ?? "/en-in/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-au",
    path: indexojFm3fXhJiMeta?.path ?? "/en-au/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-mx",
    path: indexojFm3fXhJiMeta?.path ?? "/en-mx/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-mx",
    path: indexojFm3fXhJiMeta?.path ?? "/es-mx/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-ni",
    path: indexojFm3fXhJiMeta?.path ?? "/en-ni/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-ni",
    path: indexojFm3fXhJiMeta?.path ?? "/es-ni/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-nl",
    path: indexojFm3fXhJiMeta?.path ?? "/en-nl/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-pa",
    path: indexojFm3fXhJiMeta?.path ?? "/en-pa/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-pa",
    path: indexojFm3fXhJiMeta?.path ?? "/es-pa/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-pe",
    path: indexojFm3fXhJiMeta?.path ?? "/en-pe/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-pe",
    path: indexojFm3fXhJiMeta?.path ?? "/es-pe/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-pl",
    path: indexojFm3fXhJiMeta?.path ?? "/en-pl/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___pl-pl",
    path: indexojFm3fXhJiMeta?.path ?? "/pl-pl/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-pr",
    path: indexojFm3fXhJiMeta?.path ?? "/en-pr/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-pr",
    path: indexojFm3fXhJiMeta?.path ?? "/es-pr/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-pt",
    path: indexojFm3fXhJiMeta?.path ?? "/en-pt/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___pt-pt",
    path: indexojFm3fXhJiMeta?.path ?? "/pt-pt/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-py",
    path: indexojFm3fXhJiMeta?.path ?? "/en-py/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-py",
    path: indexojFm3fXhJiMeta?.path ?? "/es-py/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-za",
    path: indexojFm3fXhJiMeta?.path ?? "/en-za/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-be",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-be/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-bo",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-bo/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-bo",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-bo/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-bz",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-bz/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-bz",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-bz/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-ca",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-ca/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-cl",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-cl/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-cl",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-cl/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-co",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-co/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-co",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-co/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-cr",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-cr/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-cr",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-cr/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-ec",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-ec/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-ec",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-ec/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-es",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-es/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-es",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-es/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-ge",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-ge/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-gb",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-gb/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-hk",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-hk/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-hu",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-hu/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___hu-hu",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/hu-hu/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-in",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-in/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-au",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-au/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-mx",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-mx/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-mx",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-mx/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-ni",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-ni/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-ni",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-ni/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-nl",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-nl/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-pa",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-pa/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-pa",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-pa/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-pe",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-pe/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-pe",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-pe/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-pl",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-pl/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___pl-pl",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/pl-pl/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-pr",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-pr/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-pr",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-pr/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-pt",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-pt/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___pt-pt",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/pt-pt/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-py",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-py/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-py",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-py/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-za",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-za/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en",
    path: indexLfZweG7Ef2Meta?.path ?? "/en/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-be",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-be/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-bo",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-bo/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-bo",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-bo/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-bz",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-bz/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-bz",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-bz/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-ca",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-ca/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-cl",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-cl/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-cl",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-cl/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-co",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-co/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-co",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-co/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-cr",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-cr/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-cr",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-cr/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-ec",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-ec/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-ec",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-ec/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-es",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-es/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-es",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-es/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-ge",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-ge/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-gb",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-gb/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-hk",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-hk/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-hu",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-hu/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___hu-hu",
    path: indexLfZweG7Ef2Meta?.path ?? "/hu-hu/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-in",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-in/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-au",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-au/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-mx",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-mx/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-mx",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-mx/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-ni",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-ni/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-ni",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-ni/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-nl",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-nl/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-pa",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-pa/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-pa",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-pa/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-pe",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-pe/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-pe",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-pe/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-pl",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-pl/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___pl-pl",
    path: indexLfZweG7Ef2Meta?.path ?? "/pl-pl/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-pr",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-pr/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-pr",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-pr/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-pt",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-pt/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___pt-pt",
    path: indexLfZweG7Ef2Meta?.path ?? "/pt-pt/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-py",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-py/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-py",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-py/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-za",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-za/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en",
    path: index0sTCdVluXDMeta?.path ?? "/en/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-be",
    path: index0sTCdVluXDMeta?.path ?? "/en-be/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-bo",
    path: index0sTCdVluXDMeta?.path ?? "/en-bo/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-bo",
    path: index0sTCdVluXDMeta?.path ?? "/es-bo/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-bz",
    path: index0sTCdVluXDMeta?.path ?? "/en-bz/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-bz",
    path: index0sTCdVluXDMeta?.path ?? "/es-bz/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-ca",
    path: index0sTCdVluXDMeta?.path ?? "/en-ca/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-cl",
    path: index0sTCdVluXDMeta?.path ?? "/en-cl/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-cl",
    path: index0sTCdVluXDMeta?.path ?? "/es-cl/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-co",
    path: index0sTCdVluXDMeta?.path ?? "/en-co/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-co",
    path: index0sTCdVluXDMeta?.path ?? "/es-co/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-cr",
    path: index0sTCdVluXDMeta?.path ?? "/en-cr/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-cr",
    path: index0sTCdVluXDMeta?.path ?? "/es-cr/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-ec",
    path: index0sTCdVluXDMeta?.path ?? "/en-ec/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-ec",
    path: index0sTCdVluXDMeta?.path ?? "/es-ec/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-es",
    path: index0sTCdVluXDMeta?.path ?? "/en-es/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-es",
    path: index0sTCdVluXDMeta?.path ?? "/es-es/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-ge",
    path: index0sTCdVluXDMeta?.path ?? "/en-ge/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-gb",
    path: index0sTCdVluXDMeta?.path ?? "/en-gb/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-hk",
    path: index0sTCdVluXDMeta?.path ?? "/en-hk/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-hu",
    path: index0sTCdVluXDMeta?.path ?? "/en-hu/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___hu-hu",
    path: index0sTCdVluXDMeta?.path ?? "/hu-hu/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-in",
    path: index0sTCdVluXDMeta?.path ?? "/en-in/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-au",
    path: index0sTCdVluXDMeta?.path ?? "/en-au/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-mx",
    path: index0sTCdVluXDMeta?.path ?? "/en-mx/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-mx",
    path: index0sTCdVluXDMeta?.path ?? "/es-mx/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-ni",
    path: index0sTCdVluXDMeta?.path ?? "/en-ni/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-ni",
    path: index0sTCdVluXDMeta?.path ?? "/es-ni/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-nl",
    path: index0sTCdVluXDMeta?.path ?? "/en-nl/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-pa",
    path: index0sTCdVluXDMeta?.path ?? "/en-pa/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-pa",
    path: index0sTCdVluXDMeta?.path ?? "/es-pa/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-pe",
    path: index0sTCdVluXDMeta?.path ?? "/en-pe/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-pe",
    path: index0sTCdVluXDMeta?.path ?? "/es-pe/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-pl",
    path: index0sTCdVluXDMeta?.path ?? "/en-pl/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___pl-pl",
    path: index0sTCdVluXDMeta?.path ?? "/pl-pl/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-pr",
    path: index0sTCdVluXDMeta?.path ?? "/en-pr/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-pr",
    path: index0sTCdVluXDMeta?.path ?? "/es-pr/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-pt",
    path: index0sTCdVluXDMeta?.path ?? "/en-pt/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___pt-pt",
    path: index0sTCdVluXDMeta?.path ?? "/pt-pt/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-py",
    path: index0sTCdVluXDMeta?.path ?? "/en-py/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-py",
    path: index0sTCdVluXDMeta?.path ?? "/es-py/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-za",
    path: index0sTCdVluXDMeta?.path ?? "/en-za/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-be",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-be/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-bo",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-bo/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-bo",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-bo/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-bz",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-bz/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-bz",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-bz/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-ca",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-ca/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-cl",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-cl/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-cl",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-cl/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-co",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-co/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-co",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-co/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-cr",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-cr/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-cr",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-cr/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-ec",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-ec/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-ec",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-ec/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-es",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-es/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-es",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-es/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-ge",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-ge/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-gb",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-gb/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-hk",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-hk/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-hu",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-hu/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___hu-hu",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/hu-hu/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-in",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-in/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-au",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-au/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-mx",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-mx/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-mx",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-mx/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-ni",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-ni/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-ni",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-ni/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-nl",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-nl/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-pa",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-pa/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-pa",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-pa/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-pe",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-pe/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-pe",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-pe/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-pl",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-pl/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___pl-pl",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/pl-pl/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-pr",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-pr/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-pr",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-pr/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-pt",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-pt/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___pt-pt",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/pt-pt/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-py",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-py/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-py",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-py/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-za",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-za/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en",
    path: indexZeiT6zQX5VMeta?.path ?? "/en/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-be",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-be/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-bo",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-bo/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-bo",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-bo/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-bz",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-bz/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-bz",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-bz/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-ca",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-ca/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-cl",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-cl/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-cl",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-cl/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-co",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-co/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-co",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-co/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-cr",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-cr/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-cr",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-cr/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-ec",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-ec/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-ec",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-ec/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-es",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-es/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-es",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-es/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-ge",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-ge/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-gb",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-gb/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-hk",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-hk/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-hu",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-hu/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___hu-hu",
    path: indexZeiT6zQX5VMeta?.path ?? "/hu-hu/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-in",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-in/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-au",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-au/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-mx",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-mx/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-mx",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-mx/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-ni",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-ni/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-ni",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-ni/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-nl",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-nl/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-pa",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-pa/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-pa",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-pa/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-pe",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-pe/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-pe",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-pe/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-pl",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-pl/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___pl-pl",
    path: indexZeiT6zQX5VMeta?.path ?? "/pl-pl/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-pr",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-pr/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-pr",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-pr/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-pt",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-pt/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___pt-pt",
    path: indexZeiT6zQX5VMeta?.path ?? "/pt-pt/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-py",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-py/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-py",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-py/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-za",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-za/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en",
    path: index9wTWHSpoWsMeta?.path ?? "/en/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-be",
    path: index9wTWHSpoWsMeta?.path ?? "/en-be/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-bo",
    path: index9wTWHSpoWsMeta?.path ?? "/en-bo/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-bo",
    path: index9wTWHSpoWsMeta?.path ?? "/es-bo/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-bz",
    path: index9wTWHSpoWsMeta?.path ?? "/en-bz/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-bz",
    path: index9wTWHSpoWsMeta?.path ?? "/es-bz/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-ca",
    path: index9wTWHSpoWsMeta?.path ?? "/en-ca/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-cl",
    path: index9wTWHSpoWsMeta?.path ?? "/en-cl/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-cl",
    path: index9wTWHSpoWsMeta?.path ?? "/es-cl/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-co",
    path: index9wTWHSpoWsMeta?.path ?? "/en-co/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-co",
    path: index9wTWHSpoWsMeta?.path ?? "/es-co/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-cr",
    path: index9wTWHSpoWsMeta?.path ?? "/en-cr/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-cr",
    path: index9wTWHSpoWsMeta?.path ?? "/es-cr/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-ec",
    path: index9wTWHSpoWsMeta?.path ?? "/en-ec/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-ec",
    path: index9wTWHSpoWsMeta?.path ?? "/es-ec/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-es",
    path: index9wTWHSpoWsMeta?.path ?? "/en-es/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-es",
    path: index9wTWHSpoWsMeta?.path ?? "/es-es/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-ge",
    path: index9wTWHSpoWsMeta?.path ?? "/en-ge/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-gb",
    path: index9wTWHSpoWsMeta?.path ?? "/en-gb/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-hk",
    path: index9wTWHSpoWsMeta?.path ?? "/en-hk/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-hu",
    path: index9wTWHSpoWsMeta?.path ?? "/en-hu/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___hu-hu",
    path: index9wTWHSpoWsMeta?.path ?? "/hu-hu/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-in",
    path: index9wTWHSpoWsMeta?.path ?? "/en-in/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-au",
    path: index9wTWHSpoWsMeta?.path ?? "/en-au/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-mx",
    path: index9wTWHSpoWsMeta?.path ?? "/en-mx/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-mx",
    path: index9wTWHSpoWsMeta?.path ?? "/es-mx/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-ni",
    path: index9wTWHSpoWsMeta?.path ?? "/en-ni/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-ni",
    path: index9wTWHSpoWsMeta?.path ?? "/es-ni/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-nl",
    path: index9wTWHSpoWsMeta?.path ?? "/en-nl/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-pa",
    path: index9wTWHSpoWsMeta?.path ?? "/en-pa/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-pa",
    path: index9wTWHSpoWsMeta?.path ?? "/es-pa/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-pe",
    path: index9wTWHSpoWsMeta?.path ?? "/en-pe/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-pe",
    path: index9wTWHSpoWsMeta?.path ?? "/es-pe/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-pl",
    path: index9wTWHSpoWsMeta?.path ?? "/en-pl/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___pl-pl",
    path: index9wTWHSpoWsMeta?.path ?? "/pl-pl/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-pr",
    path: index9wTWHSpoWsMeta?.path ?? "/en-pr/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-pr",
    path: index9wTWHSpoWsMeta?.path ?? "/es-pr/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-pt",
    path: index9wTWHSpoWsMeta?.path ?? "/en-pt/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___pt-pt",
    path: index9wTWHSpoWsMeta?.path ?? "/pt-pt/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-py",
    path: index9wTWHSpoWsMeta?.path ?? "/en-py/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-py",
    path: index9wTWHSpoWsMeta?.path ?? "/es-py/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-za",
    path: index9wTWHSpoWsMeta?.path ?? "/en-za/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en",
    path: indexjAaK7ryr1uMeta?.path ?? "/en/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-be",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-be/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-bo",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-bo/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-bo",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-bo/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-bz",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-bz/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-bz",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-bz/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-ca",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-ca/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-cl",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-cl/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-cl",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-cl/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-co",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-co/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-co",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-co/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-cr",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-cr/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-cr",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-cr/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-ec",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-ec/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-ec",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-ec/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-es",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-es/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-es",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-es/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-ge",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-ge/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-gb",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-gb/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-hk",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-hk/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-hu",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-hu/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___hu-hu",
    path: indexjAaK7ryr1uMeta?.path ?? "/hu-hu/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-in",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-in/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-au",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-au/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-mx",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-mx/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-mx",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-mx/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-ni",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-ni/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-ni",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-ni/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-nl",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-nl/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-pa",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-pa/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-pa",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-pa/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-pe",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-pe/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-pe",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-pe/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-pl",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-pl/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___pl-pl",
    path: indexjAaK7ryr1uMeta?.path ?? "/pl-pl/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-pr",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-pr/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-pr",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-pr/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-pt",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-pt/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___pt-pt",
    path: indexjAaK7ryr1uMeta?.path ?? "/pt-pt/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-py",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-py/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-py",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-py/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-za",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-za/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en",
    path: indexwJM0pWSZGwMeta?.path ?? "/en",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-be",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-be",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-bo",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-bo",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-bo",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-bo",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-bz",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-bz",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-bz",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-bz",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-ca",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-ca",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-cl",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-cl",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-cl",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-cl",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-co",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-co",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-co",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-co",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-cr",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-cr",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-cr",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-cr",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-ec",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-ec",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-ec",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-ec",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-es",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-es",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-es",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-es",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-ge",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-ge",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-gb",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-gb",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-hk",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-hk",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-hu",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-hu",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___hu-hu",
    path: indexwJM0pWSZGwMeta?.path ?? "/hu-hu",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-in",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-in",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-au",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-au",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-mx",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-mx",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-mx",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-mx",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-ni",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-ni",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-ni",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-ni",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-nl",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-nl",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-pa",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-pa",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-pa",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-pa",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-pe",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-pe",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-pe",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-pe",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-pl",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-pl",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___pl-pl",
    path: indexwJM0pWSZGwMeta?.path ?? "/pl-pl",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-pr",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-pr",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-pr",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-pr",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-pt",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-pt",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___pt-pt",
    path: indexwJM0pWSZGwMeta?.path ?? "/pt-pt",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-py",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-py",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-py",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-py",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-za",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-za",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-be",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-be/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-bo",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-bo/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-bo",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-bo/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-bz",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-bz/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-bz",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-bz/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-ca",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-ca/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-cl",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-cl/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-cl",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-cl/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-co",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-co/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-co",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-co/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-cr",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-cr/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-cr",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-cr/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-ec",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-ec/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-ec",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-ec/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-es",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-es/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-es",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-es/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-ge",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-ge/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-gb",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-gb/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-hk",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-hk/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-hu",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-hu/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___hu-hu",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/hu-hu/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-in",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-in/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-au",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-au/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-mx",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-mx/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-mx",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-mx/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-ni",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-ni/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-ni",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-ni/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-nl",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-nl/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-pa",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-pa/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-pa",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-pa/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-pe",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-pe/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-pe",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-pe/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-pl",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-pl/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___pl-pl",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/pl-pl/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-pr",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-pr/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-pr",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-pr/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-pt",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-pt/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___pt-pt",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/pt-pt/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-py",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-py/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-py",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-py/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-za",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-za/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en",
    path: indexSWPrnbyjTJMeta?.path ?? "/en/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-be",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-be/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-bo",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-bo/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-bo",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-bo/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-bz",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-bz/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-bz",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-bz/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-ca",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-ca/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-cl",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-cl/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-cl",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-cl/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-co",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-co/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-co",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-co/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-cr",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-cr/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-cr",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-cr/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-ec",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-ec/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-ec",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-ec/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-es",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-es/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-es",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-es/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-ge",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-ge/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-gb",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-gb/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-hk",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-hk/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-hu",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-hu/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___hu-hu",
    path: indexSWPrnbyjTJMeta?.path ?? "/hu-hu/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-in",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-in/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-au",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-au/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-mx",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-mx/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-mx",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-mx/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-ni",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-ni/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-ni",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-ni/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-nl",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-nl/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-pa",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-pa/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-pa",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-pa/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-pe",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-pe/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-pe",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-pe/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-pl",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-pl/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___pl-pl",
    path: indexSWPrnbyjTJMeta?.path ?? "/pl-pl/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-pr",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-pr/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-pr",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-pr/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-pt",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-pt/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___pt-pt",
    path: indexSWPrnbyjTJMeta?.path ?? "/pt-pt/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-py",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-py/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-py",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-py/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-za",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-za/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-be",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-be/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-bo",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-bo/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-bo",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-bo/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-bz",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-bz/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-bz",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-bz/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-ca",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-ca/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-cl",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-cl/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-cl",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-cl/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-co",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-co/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-co",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-co/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-cr",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-cr/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-cr",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-cr/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-ec",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-ec/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-ec",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-ec/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-es",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-es/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-es",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-es/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-ge",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-ge/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-gb",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-gb/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-hk",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-hk/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-hu",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-hu/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___hu-hu",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/hu-hu/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-in",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-in/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-au",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-au/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-mx",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-mx/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-mx",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-mx/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-ni",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-ni/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-ni",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-ni/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-nl",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-nl/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-pa",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-pa/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-pa",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-pa/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-pe",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-pe/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-pe",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-pe/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-pl",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-pl/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___pl-pl",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/pl-pl/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-pr",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-pr/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-pr",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-pr/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-pt",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-pt/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___pt-pt",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/pt-pt/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-py",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-py/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-py",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-py/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-za",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-za/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en",
    path: index2QwGis8PcNMeta?.path ?? "/en/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-be",
    path: index2QwGis8PcNMeta?.path ?? "/en-be/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-bo",
    path: index2QwGis8PcNMeta?.path ?? "/en-bo/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-bo",
    path: index2QwGis8PcNMeta?.path ?? "/es-bo/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-bz",
    path: index2QwGis8PcNMeta?.path ?? "/en-bz/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-bz",
    path: index2QwGis8PcNMeta?.path ?? "/es-bz/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-ca",
    path: index2QwGis8PcNMeta?.path ?? "/en-ca/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-cl",
    path: index2QwGis8PcNMeta?.path ?? "/en-cl/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-cl",
    path: index2QwGis8PcNMeta?.path ?? "/es-cl/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-co",
    path: index2QwGis8PcNMeta?.path ?? "/en-co/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-co",
    path: index2QwGis8PcNMeta?.path ?? "/es-co/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-cr",
    path: index2QwGis8PcNMeta?.path ?? "/en-cr/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-cr",
    path: index2QwGis8PcNMeta?.path ?? "/es-cr/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-ec",
    path: index2QwGis8PcNMeta?.path ?? "/en-ec/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-ec",
    path: index2QwGis8PcNMeta?.path ?? "/es-ec/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-es",
    path: index2QwGis8PcNMeta?.path ?? "/en-es/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-es",
    path: index2QwGis8PcNMeta?.path ?? "/es-es/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-ge",
    path: index2QwGis8PcNMeta?.path ?? "/en-ge/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-gb",
    path: index2QwGis8PcNMeta?.path ?? "/en-gb/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-hk",
    path: index2QwGis8PcNMeta?.path ?? "/en-hk/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-hu",
    path: index2QwGis8PcNMeta?.path ?? "/en-hu/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___hu-hu",
    path: index2QwGis8PcNMeta?.path ?? "/hu-hu/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-in",
    path: index2QwGis8PcNMeta?.path ?? "/en-in/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-au",
    path: index2QwGis8PcNMeta?.path ?? "/en-au/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-mx",
    path: index2QwGis8PcNMeta?.path ?? "/en-mx/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-mx",
    path: index2QwGis8PcNMeta?.path ?? "/es-mx/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-ni",
    path: index2QwGis8PcNMeta?.path ?? "/en-ni/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-ni",
    path: index2QwGis8PcNMeta?.path ?? "/es-ni/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-nl",
    path: index2QwGis8PcNMeta?.path ?? "/en-nl/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-pa",
    path: index2QwGis8PcNMeta?.path ?? "/en-pa/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-pa",
    path: index2QwGis8PcNMeta?.path ?? "/es-pa/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-pe",
    path: index2QwGis8PcNMeta?.path ?? "/en-pe/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-pe",
    path: index2QwGis8PcNMeta?.path ?? "/es-pe/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-pl",
    path: index2QwGis8PcNMeta?.path ?? "/en-pl/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___pl-pl",
    path: index2QwGis8PcNMeta?.path ?? "/pl-pl/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-pr",
    path: index2QwGis8PcNMeta?.path ?? "/en-pr/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-pr",
    path: index2QwGis8PcNMeta?.path ?? "/es-pr/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-pt",
    path: index2QwGis8PcNMeta?.path ?? "/en-pt/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___pt-pt",
    path: index2QwGis8PcNMeta?.path ?? "/pt-pt/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-py",
    path: index2QwGis8PcNMeta?.path ?? "/en-py/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-py",
    path: index2QwGis8PcNMeta?.path ?? "/es-py/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-za",
    path: index2QwGis8PcNMeta?.path ?? "/en-za/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en",
    path: indexjNcQzb30nMMeta?.path ?? "/en/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-be",
    path: indexjNcQzb30nMMeta?.path ?? "/en-be/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-bo",
    path: indexjNcQzb30nMMeta?.path ?? "/en-bo/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-bo",
    path: indexjNcQzb30nMMeta?.path ?? "/es-bo/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-bz",
    path: indexjNcQzb30nMMeta?.path ?? "/en-bz/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-bz",
    path: indexjNcQzb30nMMeta?.path ?? "/es-bz/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-ca",
    path: indexjNcQzb30nMMeta?.path ?? "/en-ca/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-cl",
    path: indexjNcQzb30nMMeta?.path ?? "/en-cl/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-cl",
    path: indexjNcQzb30nMMeta?.path ?? "/es-cl/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-co",
    path: indexjNcQzb30nMMeta?.path ?? "/en-co/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-co",
    path: indexjNcQzb30nMMeta?.path ?? "/es-co/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-cr",
    path: indexjNcQzb30nMMeta?.path ?? "/en-cr/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-cr",
    path: indexjNcQzb30nMMeta?.path ?? "/es-cr/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-ec",
    path: indexjNcQzb30nMMeta?.path ?? "/en-ec/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-ec",
    path: indexjNcQzb30nMMeta?.path ?? "/es-ec/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-es",
    path: indexjNcQzb30nMMeta?.path ?? "/en-es/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-es",
    path: indexjNcQzb30nMMeta?.path ?? "/es-es/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-ge",
    path: indexjNcQzb30nMMeta?.path ?? "/en-ge/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-gb",
    path: indexjNcQzb30nMMeta?.path ?? "/en-gb/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-hk",
    path: indexjNcQzb30nMMeta?.path ?? "/en-hk/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-hu",
    path: indexjNcQzb30nMMeta?.path ?? "/en-hu/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___hu-hu",
    path: indexjNcQzb30nMMeta?.path ?? "/hu-hu/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-in",
    path: indexjNcQzb30nMMeta?.path ?? "/en-in/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-au",
    path: indexjNcQzb30nMMeta?.path ?? "/en-au/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-mx",
    path: indexjNcQzb30nMMeta?.path ?? "/en-mx/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-mx",
    path: indexjNcQzb30nMMeta?.path ?? "/es-mx/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-ni",
    path: indexjNcQzb30nMMeta?.path ?? "/en-ni/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-ni",
    path: indexjNcQzb30nMMeta?.path ?? "/es-ni/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-nl",
    path: indexjNcQzb30nMMeta?.path ?? "/en-nl/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-pa",
    path: indexjNcQzb30nMMeta?.path ?? "/en-pa/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-pa",
    path: indexjNcQzb30nMMeta?.path ?? "/es-pa/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-pe",
    path: indexjNcQzb30nMMeta?.path ?? "/en-pe/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-pe",
    path: indexjNcQzb30nMMeta?.path ?? "/es-pe/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-pl",
    path: indexjNcQzb30nMMeta?.path ?? "/en-pl/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___pl-pl",
    path: indexjNcQzb30nMMeta?.path ?? "/pl-pl/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-pr",
    path: indexjNcQzb30nMMeta?.path ?? "/en-pr/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-pr",
    path: indexjNcQzb30nMMeta?.path ?? "/es-pr/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-pt",
    path: indexjNcQzb30nMMeta?.path ?? "/en-pt/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___pt-pt",
    path: indexjNcQzb30nMMeta?.path ?? "/pt-pt/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-py",
    path: indexjNcQzb30nMMeta?.path ?? "/en-py/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-py",
    path: indexjNcQzb30nMMeta?.path ?? "/es-py/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-za",
    path: indexjNcQzb30nMMeta?.path ?? "/en-za/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en",
    path: index1s4p7SZOGtMeta?.path ?? "/en/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-be",
    path: index1s4p7SZOGtMeta?.path ?? "/en-be/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-bo",
    path: index1s4p7SZOGtMeta?.path ?? "/en-bo/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-bo",
    path: index1s4p7SZOGtMeta?.path ?? "/es-bo/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-bz",
    path: index1s4p7SZOGtMeta?.path ?? "/en-bz/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-bz",
    path: index1s4p7SZOGtMeta?.path ?? "/es-bz/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-ca",
    path: index1s4p7SZOGtMeta?.path ?? "/en-ca/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-cl",
    path: index1s4p7SZOGtMeta?.path ?? "/en-cl/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-cl",
    path: index1s4p7SZOGtMeta?.path ?? "/es-cl/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-co",
    path: index1s4p7SZOGtMeta?.path ?? "/en-co/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-co",
    path: index1s4p7SZOGtMeta?.path ?? "/es-co/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-cr",
    path: index1s4p7SZOGtMeta?.path ?? "/en-cr/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-cr",
    path: index1s4p7SZOGtMeta?.path ?? "/es-cr/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-ec",
    path: index1s4p7SZOGtMeta?.path ?? "/en-ec/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-ec",
    path: index1s4p7SZOGtMeta?.path ?? "/es-ec/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-es",
    path: index1s4p7SZOGtMeta?.path ?? "/en-es/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-es",
    path: index1s4p7SZOGtMeta?.path ?? "/es-es/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-ge",
    path: index1s4p7SZOGtMeta?.path ?? "/en-ge/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-gb",
    path: index1s4p7SZOGtMeta?.path ?? "/en-gb/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-hk",
    path: index1s4p7SZOGtMeta?.path ?? "/en-hk/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-hu",
    path: index1s4p7SZOGtMeta?.path ?? "/en-hu/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___hu-hu",
    path: index1s4p7SZOGtMeta?.path ?? "/hu-hu/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-in",
    path: index1s4p7SZOGtMeta?.path ?? "/en-in/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-au",
    path: index1s4p7SZOGtMeta?.path ?? "/en-au/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-mx",
    path: index1s4p7SZOGtMeta?.path ?? "/en-mx/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-mx",
    path: index1s4p7SZOGtMeta?.path ?? "/es-mx/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-ni",
    path: index1s4p7SZOGtMeta?.path ?? "/en-ni/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-ni",
    path: index1s4p7SZOGtMeta?.path ?? "/es-ni/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-nl",
    path: index1s4p7SZOGtMeta?.path ?? "/en-nl/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-pa",
    path: index1s4p7SZOGtMeta?.path ?? "/en-pa/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-pa",
    path: index1s4p7SZOGtMeta?.path ?? "/es-pa/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-pe",
    path: index1s4p7SZOGtMeta?.path ?? "/en-pe/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-pe",
    path: index1s4p7SZOGtMeta?.path ?? "/es-pe/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-pl",
    path: index1s4p7SZOGtMeta?.path ?? "/en-pl/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___pl-pl",
    path: index1s4p7SZOGtMeta?.path ?? "/pl-pl/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-pr",
    path: index1s4p7SZOGtMeta?.path ?? "/en-pr/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-pr",
    path: index1s4p7SZOGtMeta?.path ?? "/es-pr/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-pt",
    path: index1s4p7SZOGtMeta?.path ?? "/en-pt/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___pt-pt",
    path: index1s4p7SZOGtMeta?.path ?? "/pt-pt/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-py",
    path: index1s4p7SZOGtMeta?.path ?? "/en-py/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-py",
    path: index1s4p7SZOGtMeta?.path ?? "/es-py/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-za",
    path: index1s4p7SZOGtMeta?.path ?? "/en-za/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-be",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-be/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-bo",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-bo/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-bo",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-bo/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-bz",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-bz/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-bz",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-bz/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-ca",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-ca/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-cl",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-cl/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-cl",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-cl/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-co",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-co/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-co",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-co/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-cr",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-cr/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-cr",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-cr/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-ec",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-ec/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-ec",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-ec/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-es",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-es/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-es",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-es/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-ge",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-ge/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-gb",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-gb/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-hk",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-hk/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-hu",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-hu/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___hu-hu",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/hu-hu/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-in",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-in/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-au",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-au/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-mx",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-mx/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-mx",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-mx/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-ni",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-ni/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-ni",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-ni/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-nl",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-nl/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-pa",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-pa/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-pa",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-pa/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-pe",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-pe/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-pe",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-pe/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-pl",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-pl/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___pl-pl",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/pl-pl/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-pr",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-pr/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-pr",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-pr/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-pt",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-pt/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___pt-pt",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/pt-pt/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-py",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-py/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-py",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-py/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-za",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-za/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en",
    path: indexi5j1BAjR1wMeta?.path ?? "/en/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-be",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-be/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-bo",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-bo/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-bo",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-bo/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-bz",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-bz/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-bz",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-bz/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-ca",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-ca/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-cl",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-cl/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-cl",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-cl/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-co",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-co/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-co",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-co/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-cr",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-cr/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-cr",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-cr/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-ec",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-ec/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-ec",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-ec/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-es",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-es/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-es",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-es/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-ge",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-ge/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-gb",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-gb/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-hk",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-hk/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-hu",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-hu/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___hu-hu",
    path: indexi5j1BAjR1wMeta?.path ?? "/hu-hu/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-in",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-in/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-au",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-au/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-mx",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-mx/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-mx",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-mx/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-ni",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-ni/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-ni",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-ni/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-nl",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-nl/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-pa",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-pa/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-pa",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-pa/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-pe",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-pe/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-pe",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-pe/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-pl",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-pl/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___pl-pl",
    path: indexi5j1BAjR1wMeta?.path ?? "/pl-pl/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-pr",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-pr/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-pr",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-pr/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-pt",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-pt/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___pt-pt",
    path: indexi5j1BAjR1wMeta?.path ?? "/pt-pt/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-py",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-py/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-py",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-py/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-za",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-za/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  }
]